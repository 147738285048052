import {CaseMessageRole} from "./Conversation";
import moment from "moment/moment";
import {SupportCase} from "./model";

export function toRole(roleString: string): CaseMessageRole {
    switch (roleString) {
        case "request":
            return CaseMessageRole.REQUEST
        case "response":
            return CaseMessageRole.RESPONSE
        case "escalation":
            return CaseMessageRole.ESCALATION
        case "escalation_response":
            return CaseMessageRole.ESCALATION_RESPONSE
        case "summary":
            return CaseMessageRole.SUMMARY
        default:
            throw new Error(`Unknown role: ${roleString}`)
    }
}

export function formatTimeStamp(timestamp: number): string {
    return moment(new Date(timestamp * 1000)).format("YYYY-MMM-DD HH:mm:ss")
}

export function onlyIfUnchanged(startingSupportCaseId: string | null, f: (c: SupportCase) => SupportCase): (c: SupportCase) => SupportCase {
    return (supportCase: SupportCase) => {
        if (startingSupportCaseId !== supportCase?.case_id) {
            return supportCase
        }
        return f(supportCase)
    }
}

