import {CaseMessage, CaseMessageRole, CaseMessageType, Conversation} from "./Conversation";
import {CaseInput} from "./CaseInput";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Spinner} from "flowbite-react";
import {formatTimeStamp} from "./utils";
import {CaseMessageResponse} from "./model";
import {Feedback} from "./Feedback";
import {SupportCaseState, useSupportCase} from "./CaseHook";
import {useAuth} from "../auth/AuthHook";
import {useErrorReporter} from "./ErrorViewer";
import Countdown from "react-countdown";
import moment from "moment";
import {Progress} from "../events/Progress";
import {Activity} from "../events/ProgressRenderers";

interface DiscussionProps {
}

export function Discussion({}: DiscussionProps) {
    const {t} = useTranslation()
    const [productVersions, setProductVersions] = useState<{ [id: string]: string }>({})
    const {isLoggedIn, getUserId} = useAuth()
    const {updateSupportCase, deleteSupportCase, supportCaseState, caseId, limits, fetchData} = useSupportCase()
    const reporter = useErrorReporter()
    const [isInCooldown, setIsInCooldown] = useState(false)
    const handleVersionChange = useCallback((product: string, version: string) => {
        setProductVersions((old) => {
            const newVersions = {...old}
            if (version == "" && product in newVersions)
                delete newVersions[product]
            if (version != "")
                newVersions[product] = version
            return newVersions
        })
    }, [setProductVersions])
    const discussionConversationRef = useRef<HTMLInputElement>(null);
    const lastMessageRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (supportCaseState?.isLoading === true && discussionConversationRef.current) {
                discussionConversationRef.current.scrollTop = discussionConversationRef.current.scrollHeight;
            }

    }, [supportCaseState?.isLoading, supportCaseState?.progress]);

    useEffect(() => {
        if(supportCaseState?.isLoading === false && lastMessageRef.current) {
            lastMessageRef.current.scrollIntoView({
                behavior: "smooth",
                block: "end",
                inline: "nearest"
            })
        }
    }, [supportCaseState?.isLoading, supportCaseState?.supportCase?.messages]);

    const setWaitingForResponse = useCallback((waiting: boolean) => {
        updateSupportCase((prevState) => {
            return {
                ...prevState,
                isLoading: waiting,
                progress: (waiting !== prevState.isLoading) ? new Map() : prevState.progress
            }
        })
    }, [updateSupportCase])


    const addNewMessage = useCallback((msg: CaseMessage) => {
        updateSupportCase((c) => {
            return {
                ...c,
                supportCase: {
                    ...c.supportCase!,
                    messages: [...c.supportCase?.messages ?? [], msg]
                }
            } as SupportCaseState
        })
    }, [updateSupportCase])



    const removeLastMessage = useCallback((msg: CaseMessage) => {
        updateSupportCase((c) => {
            return {
                supportCase: {
                    ...c.supportCase!,
                    messages: c?.supportCase?.messages.filter((m) => m !== msg)
                },
                isLoading: c.isLoading,
                error: c.error
            } as SupportCaseState
        })
    }, [updateSupportCase])

    const handleNewQuestion = useCallback(async (input: string, file_id?: string): Promise<boolean> => {
        const msg = {
            text: input,
            messageType: CaseMessageType.USER_MESSAGE,
            messageRole: CaseMessageRole.REQUEST,
            requestedBy: getUserId(),
            attachment_id: file_id
        };
        setWaitingForResponse(true)
        try {
            addNewMessage(msg);
            const response = await fetchData(`/api/cases/${caseId ?? "undefined"}/ask`, "POST", {
                "message": input,
                "attachment_id": file_id
            });
            if (!response.ok || response.status > 299) {
                removeLastMessage(msg);
                return false
            }
            const parsedResponse: CaseMessageResponse = await response.json();
            addNewMessage({
                text: parsedResponse.text,
                messageType: CaseMessageType.MESSAGE_RESPONSE,
                messageRole: CaseMessageRole.RESPONSE,
                timeToRespond: parsedResponse.time_to_respond
            });
            return true
        } catch (e) {
            removeLastMessage(msg);
            return false
        } finally {
            setWaitingForResponse(false)
        }

    }, [caseId, removeLastMessage, getUserId, addNewMessage, setWaitingForResponse, fetchData])

    const handleNewMessage = useCallback(async (input: string, file_id?: string): Promise<boolean> => {
        if (Object.keys(productVersions).length === 0) {
            return await handleNewQuestion(input, file_id)
        }

        const withVersions = `${input}\n\nHere are the product versions we use:\n\`\`\`json\n${(JSON.stringify(productVersions))}\n\`\`\``
        setProductVersions({})
        return await handleNewQuestion(withVersions, file_id)
    }, [handleNewQuestion, productVersions, setProductVersions])

    const resetCoolDown = useCallback(() => {
        setIsInCooldown(false)
    }, [setIsInCooldown])

    const supportCase = supportCaseState?.supportCase

    const isReallyInCoolDown = limits.lastUpdated != undefined && Date.now() - limits.lastUpdated < limits.cooldown * 1000
    if (isReallyInCoolDown != isInCooldown) {
        setIsInCooldown(isReallyInCoolDown)
    }
    return (

        <div id="discussion-frame" className={`flex flex-col pt-4 grow h-full w-full justify-center items-center overflow-y-auto`}>
            {caseId == undefined || !isLoggedIn() ? (
                    <div id="logo-container" className="flex flex-col justify-center items-center space-y-4">
                        <img src="/favicon.png" className="h-12 w-fit self-center"/>
                        <div className="text-xl text-blue-enov text-center font-bold">
                            NESTOR.AI
                        </div>
                        <div className="text-xl text-white text-center">
                            {t("Technical support assistant")}
                        </div>
                        {
                            !isLoggedIn() && (
                                <div className="text-xl text-white text-center">
                                    {t("Log in to access support case")}
                                </div>
                            )
                        }
                    </div>)
                :
                (
                    <div id="conv-n-in" className="flex flex-col grow overflow-y-auto w-full">
                        <div id="discussion-conversation-container" className="flex flex-col overflow-y-auto grow w-full" ref={discussionConversationRef}>
                            <Conversation
                            messages={supportCaseState?.supportCase?.messages ?? []}
                            caseId={caseId}
                            jiraProjectKey={supportCaseState?.supportCase?.issue_tracker?.project_key}
                            isSyncing={supportCaseState?.isSyncing === true}
                            onVersionChange={handleVersionChange}
                            lastMessageRef={lastMessageRef}/>
                            {supportCaseState?.isLoading === true && (
                                <div className={`flex flex-col w-full gap-2 p-6 items-center`}>
                                    <Progress
                                        progressMap={supportCaseState?.progress ?? new Map()}
                                        excludedActivities={[Activity.TECH_DOC_SEARCH, Activity.COMM_CASE_SEARCH ]}
                                    />
                                </div>
                            )}
                            {supportCaseState?.isSyncing === true && (
                                <div className={`flex flex-col w-full gap-2 p-6`}>
                                    <Spinner
                                        color="gray"
                                        aria-label="Waiting for background sync spinner"
                                        size="lg"
                                        className="animate-spin"/>
                                </div>
                            )}
                            {
                                supportCase?.status.toLowerCase() === "closed" && (
                                    <div className={`flex flex-col w-full gap-2 p-6`}>
                                        <div className="text-center text-xl text-red-500">
                                            {supportCase?.closure_time === undefined || supportCase?.closure_time <= 0 ? "Case is closed" : t("Case is closed at", {closureTime: formatTimeStamp(supportCase?.closure_time)})}
                                        </div>
                                        <Feedback caseId={caseId} feedback={supportCase?.feedback}/>
                                    </div>
                                )
                            }
                        </div>
                        { isInCooldown && (
                            <div className="flex flex-col justify-center items-center text-white text-sm py-2">
                                <div>{`You have reached your limits. Wait time until next message: `}</div>
                                <div className="px-2">
                                    <Countdown date={(limits.lastUpdated ?? 0) + limits.cooldown * 1000} autoStart={true} onComplete={resetCoolDown}/>
                                </div>
                            </div>
                        )
                        }
                        <div className="flex flex-row justify-center items-center text-white text-sm hidden">
                            {`Debug info on limits: You have ${limits.limit} messages in ${limits.period} seconds, remaining: ${limits.remaining}, cooldown: ${Math.round(limits.cooldown)}, last updated: ${moment(new Date(limits.lastUpdated!)).format("YYYY-MMM-DD HH:mm:ss")}, time since last updated: ${Math.round((Date.now() - limits.lastUpdated!) / 1000)}`}
                        </div>
                        <CaseInput
                            inputHandler={handleNewMessage}
                            disabled={supportCaseState?.isLoading === true || supportCase?.status.toLowerCase() === "closed" || isInCooldown}
                            caseId={caseId}/>
                    </div>
                )}
        </div>
    )
}
