import {WsEvent} from "../support/CaseHook";
import React from "react";
import {
    ParallelSearchEndEvent,
    ParallelSearchProgressEvent,
    ParallelSearchStartEvent, RerankingEndEvent,
    SearchEndEvent, VersionInfoEndEvent
} from "./ProgressEvents";

export function TechDocSearchProgress({events}: { events: WsEvent[] }) {
    const searchEndEvent = getEnd(events) as SearchEndEvent
    const hasEnd = searchEndEvent !== undefined;
    if (!hasEnd) {
        return <div>Searching in technical documentation ...</div>
    }
    return (
        <div>
            <span>Searching in technical documentation ... </span>
            <span className={"font-bold text-green-500"}>finished</span>
            <span>. Found {searchEndEvent.hit_count} documents</span>
        </div>
    )
}

export function ParallelSearchProgress({events}: { events: WsEvent[] }) {
    const startEvent = getStart(events) as ParallelSearchStartEvent;
    const progressEvents = getAllProgress(events) as ParallelSearchProgressEvent[];
    const totalHits = progressEvents.reduce((acc, event) => acc + event.hit_count, 0)
    const endEvent = getEnd(events) as ParallelSearchEndEvent;
    return (
        <div>
            <span className={"animate-fade"}>Searching in {startEvent.search_count} sources in parallel ...</span>
                {endEvent === undefined && progressEvents.length > 0 && (
                    <span className={"animate-fade"}>
                        <span> Found </span>
                        <span className={"font-bold text-green-500"}>{totalHits}</span>
                        <span> hits so far ...</span>
                    </span>
                )}
            {endEvent !== undefined && (
                <span className={"animate-fade"}>
                    <span className={"font-bold text-green-500"}> finished</span>
                    <span>. Found {endEvent.hit_count} hits.</span>
                </span>
            )}
        </div>
    )
}

function getStart(events: WsEvent[]) {
    return events.find(event => event.position === "start");
}

function getEnd(events: WsEvent[]) {
    return events.find(event => event.position === "end");
}

function getAllProgress(events: WsEvent[]) {
    return events.filter(event => event.position === "progress");
}

export enum Activity {
    TECH_DOC_SEARCH = "tech_doc_search",
    COMM_CASE_SEARCH = "community_case_search",
    PARALLEL_SEARCH = "parallel_search",
    RESPONSE_GENERATION = "response_generator",
    VERSION_INFO = "version_info",
    RERANKING = "reranking",
}

export function StartEndProgress({events, start, end}: {
    events: WsEvent[],
    start: React.ReactNode,
    end: React.ReactNode
}) {
    const startEvent = getStart(events);
    const endEvent = getEnd(events);
    if (endEvent !== undefined) {
        return end
    }
    return start
}

export function ResponseGenerationProgress({events}: { events: WsEvent[] }) {
    return <StartEndProgress
        events={events}
        start={<div><span className={"animate-fade"}> Generating response ...</span></div>}
        end={<div>
            <span className={"animate-fade"}> Generating response ...</span><span
            className={"font-bold text-green-500 animate-fade"}> finished</span>
        </div>}
    />
}

export function VersionInformationProgress({events}: { events: WsEvent[] }) {
    const endEvent = getEnd(events) as VersionInfoEndEvent;
    if (endEvent !== undefined) {
        const versions = Object.entries(endEvent.supplied_versions).map(([product, version]) => `${product}: ${version}`).join(", ")
        return (
            <div>
                <span className={"animate-fade"}> Analyzing product information ... </span>
                <span className={"animate-fade"}>
                    <span className={"font-bold text-green-500"}>finished</span>
                    <span>. Affected: {endEvent.affected_products.join(", ")}. Versions: {versions}</span>
                </span>
            </div>
        )
    }
    return <div><span className={"animate-fade"}> Analyzing product information ...</span></div>
}

export function RerankingProgress({events}: { events: WsEvent[] }) {
    const endEvent = getEnd(events) as RerankingEndEvent;
    return (
        <div>
            <span className={"animate-fade"}>Analyzing search results ...</span>
            {endEvent !== undefined && (
                <span className={"animate-fade"}>
                    <span className={"font-bold text-green-500"}> finished</span>
                    <span>. Picked {endEvent.tech_doc} document fragments, {endEvent.case_extract} support case extracts and {endEvent.comm_case} community cases.</span>
                </span>
            )
            }
        </div>
    )


}

export const rendererMap = new Map<string, React.FC<{ events: WsEvent[] }>>(
    [
        [Activity.TECH_DOC_SEARCH, TechDocSearchProgress],
        [Activity.PARALLEL_SEARCH, ParallelSearchProgress],
        [Activity.RESPONSE_GENERATION, ResponseGenerationProgress],
        [Activity.VERSION_INFO, VersionInformationProgress],
        [Activity.RERANKING, RerankingProgress]
    ]
)