import {WsEvent} from "../support/CaseHook";
import React, {useEffect, useRef} from "react";
import {RobotIcon} from "../components/Icons";
import {rendererMap} from "./ProgressRenderers";

export interface ProgressProps {
    progressMap: Map<number, WsEvent[]>
    excludedActivities?: string[]

}

export function Progress({progressMap, excludedActivities}: ProgressProps) {
    const scrollRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
    }, [progressMap]);
    const progressArray = Array.from(progressMap);
    progressArray.sort(([key1], [key2]) => key1 - key2)

    const filteredProgressArray = progressArray.filter(([_, events]) => {
        return excludedActivities === undefined || !excludedActivities.includes(events[0].activity)
    })

    return (
        <div className={"text-left flex flex-col pl-10 pr-14 max-w-[850px] w-full items-start"} ref={scrollRef}>
            <div className="flex flex-row w-full py-2 overflow-x-auto">
                <div className="flex flex-row">
                    <RobotIcon/>
                </div>
                <div className="flex flex-col grow overflow-x-auto">
                    <div className="px-4 grow">
                        <div className="text-blue-enov font-bold text-sm">
                            Nestor.ai
                        </div>
                    </div>
                    <div className="pb-8 flex flex-col grow w-full overflow-x-auto transition-all duration-1000 ease-in-out">
                        {
                            filteredProgressArray.length === 0 ?
                                <p className="text-left flex flex-col w-full px-4 animate-fade">Waiting for activities to start ...</p> :
                                <ul className={"text-left flex flex-col w-full px-4"}>
                                    {
                                        renderAll(filteredProgressArray)
                                    }
                                </ul>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

function renderAll(progressArray: [number, WsEvent[]][]) {
    const res = progressArray.map(([key, events]) => {
        if (rendererMap.has(events[0].activity)) {
            const Renderer = rendererMap.get(events[0].activity) as React.FC<{ events: WsEvent[] }>
            return <li key={key} className={"animate-fade"}> <Renderer events={events}/></li>
        } else {
            return renderUnknownActivity(events, key)

        }
    });
    const activityWithoutEnd = progressArray.find(([_, events]) => events.find(event => event.position === "end") === undefined)
    if (activityWithoutEnd === undefined) {
        res.push(<li key="final item" className={"animate-fade"}>Hang on ...</li>)
    }
    return res
}

function renderUnknownActivity(events: WsEvent[], key: number) {
    const endEvent = events.find(event => event.position === "end")
    const activity = events[0].activity

    return endEvent != undefined ?
        <li key={key} className={""}>Executing activity of {activity} done.</li> :
        <li key={key} className={""}>Executing activity of {activity} ...</li>
}
