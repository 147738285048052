import {createContext, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {BackendMessageResponse, SupportCase} from "./model";
import {useParams} from "react-router-dom";
import {CaseMessage, CaseMessageRole, CaseMessageType} from "./Conversation";
import {toRole} from "./utils";
import {useAuth} from "../auth/AuthHook";
import {useErrorReporter} from "./ErrorViewer";
import {ExponentialBackoff, Websocket, WebsocketBuffer, WebsocketBuilder} from "websocket-ts";

const emptyCase = () => {
    return {
        case_id: null,
        title: "",
        severity: "",
        created_by: "",
        status: "",
        messages: []
    } as SupportCase
}

export interface SupportCaseState {
    supportCase?: SupportCase
    isLoading: boolean
    isSyncing: boolean
    progress: Map<number, WsEvent[]>
    error?: string
}

interface CaseStateMapContextType {
    caseStateMap: Map<string, SupportCaseState>
    setCaseStateMap: (caseStateMapSetter: (prevState: Map<string, SupportCaseState>) => Map<string, SupportCaseState>) => void,
    webSocket?: Websocket,
    webSocketReadyState?: number
}

interface SendingLimitsType {
    limit: number,
    period: number,
    remaining: number,
    cooldown: number,
    lastUpdated?: number
}

interface SendingLimitsContextType {
    limits: SendingLimitsType
    setLimits: (limitsSetter: (prevState: SendingLimitsType) => SendingLimitsType) => void
}

export const CaseStateMapContext = createContext<CaseStateMapContextType | undefined>(undefined)
export const SendingLimitsContext = createContext<SendingLimitsContextType>({
    limits: {
        limit: 0,
        period: 0,
        remaining: -1,
        cooldown: 0,
        lastUpdated: undefined
    },
    setLimits: () => {
        throw new Error("setLimits must be used within a SendingLimitsProvider")
    }
})

const useCaseStateContext = () => {
    const context = useContext(CaseStateMapContext)
    if (context === undefined) {
        throw new Error('useCaseStateContext must be used within a CaseStateMapProvider')
    }
    return context
}

export interface WsEvent {
    position: "start" | "progress" | "end"
    activity: string
    json_name: string
}

export interface EventWrapper {
    org_id: string
    json_name: string
}

export interface ReadyForSendingEvent extends EventWrapper {
}

export interface Scheduler {
    setTimeout(fn: () => void, timeout: number): NodeJS.Timeout | number
    clearTimeout(t: NodeJS.Timeout | number): void
}


const realScheduler: Scheduler = {
    setTimeout: (fn: () => void, timeout: number) => setTimeout(fn, timeout),
    clearTimeout: (t) => clearTimeout(t)
}

export interface ContextualEvent extends EventWrapper {
    case_id: string
    index: number
    event: WsEvent
}

export const CaseStateMapProvider: React.FC<React.PropsWithChildren<{urlFn?: () => URL, scheduler?: Scheduler}>> = (
    {
        children,
        urlFn = () => new URL(window.location.href),
        scheduler = realScheduler
    }) => {
    const urlWithoutPath = urlFn()
    const [caseStateMap, setCaseStateMap] = useState<Map<string, SupportCaseState>>(new Map())
    const {getHeaders, isLoggedIn} = useAuth()
    const [backendUrl, setBackendUrl] = useState<URL>(urlWithoutPath)
    const [webSocket, setWebSocket] = useState<[Websocket, number] | undefined>(undefined)
    const [webSocketCounter, setWebSocketCounter] = useState(0)
    urlWithoutPath.pathname = ""
    if (backendUrl.href != urlWithoutPath.href) {
        console.log(`Backend URL changed from ${backendUrl.href} to ${urlFn().href}`)
        setBackendUrl(urlWithoutPath)
    }
    const handleWsEvent = useCallback((event: EventWrapper, ws: Websocket) => {
        setWebSocket((prevState) => {
            console.log(`Event received: ${(JSON.stringify(event))}`)
            let newState = prevState
            if (prevState !== undefined && prevState[0] === ws) {
                newState = [ws, ws.readyState]
                console.log(`Websocket state in event handler was: ${prevState[1]}, now: ${ws.readyState}`)
            }
            return newState
        })
        const contextEvent = event as ContextualEvent
        if (contextEvent.event === undefined) {
            return
        }

        setCaseStateMap(prevState => {
            const caseId = contextEvent.case_id
            const prevCaseState = prevState.get(caseId);
            if (prevCaseState === undefined) {
                return prevState
            }
            const progress = new Map(prevCaseState.progress)
            const activity_events = progress.get(contextEvent.index) || []
            activity_events.push(contextEvent.event)
            progress.set(contextEvent.index, activity_events)
            const newCaseState = {
                ...prevCaseState,
                progress: progress
            }
            const res = new Map(prevState);
            res.set(caseId, newCaseState)
            return res
        })
    }, [])
    useEffect(() => {
        console.log('getHeaders changed')
    }, [getHeaders])

    useEffect(() => {
        const t = scheduler.setTimeout(() => {
            setWebSocketCounter(prevState => prevState + 1)
        }, 50 * 60 *1000)
        return () => {
            scheduler.clearTimeout(t)
        }
    }, [webSocket, scheduler])

    useEffect(() => {
        const connectWs = async () => {
            try {

                console.log(`Websocket creation round ${webSocketCounter}`)
                if (!isLoggedIn()) {
                    return
                }
                const host = backendUrl.host
                const isSecure = backendUrl.protocol.startsWith("https")
                const headers = await getHeaders()
                const queryParams: string[] = []
                for (const key in headers) {
                    queryParams.push(`${key}=${headers[key]}`)
                }

                const ws = new WebsocketBuilder(`${isSecure ? "wss" : "ws"}://${host}/api/observer/ws?${queryParams.join("&")}`)
                    .onOpen((e) => {
                        console.log(`Websocket opened in case hook, readyState: ${e.readyState}`, e)
                        setWebSocket((prevState) => {
                            let newState = prevState
                            if (prevState?.[0] === e) {
                                newState = [e, e.readyState]
                            }
                            return newState
                        })
                    })
                    .onClose((e) => {
                        console.log("Websocket closed in case hook", e)
                        setWebSocket((prevState) => {
                            let newState = prevState
                            if (prevState?.[0] === e) {
                                newState = [e, e.readyState]
                            }
                            return newState
                        })

                    })
                    .withBackoff(new ExponentialBackoff(200, 10000))
                    .withBuffer(new ListBuffer())
                    .onMessage((ws2, e) => {
                        const json_text = e.data as string
                        const message = JSON.parse(json_text) as EventWrapper
                        handleWsEvent(message, ws2)
                    })
                    .onReconnect((e) => console.log("Websocket reconnect in case hook", e))
                    .onError((e) => console.log("Websocket error in case hook", e))
                    .onRetry(async (e) => {
                        console.log("Websocket retry in case hook", e)
                        try {
                            const newHeaders = await getHeaders()
                            if (newHeaders !== headers) {
                                console.log(`Headers changed from ${headers} to ${newHeaders}`)
                                setWebSocketCounter(prevState => prevState + 1)
                            }
                        } catch (e) {
                            ws.close()
                            setWebSocket((prevState) => {
                                let newState = prevState
                                if (prevState?.[0] === ws) {
                                    newState = undefined
                                }
                                console.log(`Error getting headers: ${e}, setting ws to ${newState}`)
                                return newState
                            })
                            console.log(`Error getting headers: ${e}`)
                        }
                    })
                    .onReconnect((e) => console.log("Websocket reconnect in case hook", e))
                    .build()
                console.log(`Created ws, state: ${ws.readyState}`)
                setWebSocket(prevState => {
                    if (prevState !== undefined) {
                        const [prevWs, _] = prevState
                        if (prevWs !== ws) {
                            console.log(`Closing previous ws ${prevWs}, new is ${ws} ...`)
                            prevWs.close()
                        }
                    }
                    return [ws, ws.readyState]
                })
                console.log("Sending client ready event ...")
                ws.send('{"json_name": "client_ready_event"}')
            } catch (e) {
                console.log(`Error getting headers: ${e}`)
                setWebSocket(_ => undefined)
            }
        }
        connectWs()
    }, [isLoggedIn, getHeaders, handleWsEvent, backendUrl, webSocketCounter])

    return (
        <CaseStateMapContext.Provider value={{
            caseStateMap,
            setCaseStateMap,
            webSocket: webSocket?.[0],
            webSocketReadyState: webSocket?.[1] ?? WebSocket.CLOSED
        }}>
            {children}
        </CaseStateMapContext.Provider>
    )
}

export const SendingLimitsProvider: React.FC<React.PropsWithChildren<{}>> = ({children}) => {
    const [limits, setLimits] = useState<SendingLimitsType>({
        limit: 0,
        period: 0,
        remaining: -1,
        cooldown: 0,
        lastUpdated: undefined
    })
    return (
        <SendingLimitsContext.Provider value={{
            limits,
            setLimits
        }}>
            {children}
        </SendingLimitsContext.Provider>
    )
}

export const useSupportCase = () => {
    const {caseId} = useParams()
    const {getHeaders, isLoggedIn} = useAuth()
    const reporter = useErrorReporter()
    const {caseStateMap, setCaseStateMap, webSocket, webSocketReadyState} = useCaseStateContext()
    const [lastCaseIdLoaded, setLastCaseIdLoaded] = useState<string | undefined>(undefined)
    const {limits, setLimits} = useContext(SendingLimitsContext)


    const updateSupportCase = useCallback((updateFn: (supportCase: SupportCaseState) => SupportCaseState) => {
        setCaseStateMap(prevState => {
            if (caseId === null || caseId === undefined) {
                return prevState
            }
            const prevCaseState = prevState.get(caseId);
            const newCaseState = updateFn(prevCaseState || {
                supportCase: emptyCase(),
                isLoading: false,
                isSyncing: false,
                progress: new Map()
            })
            const res = new Map(prevState);
            res.set(caseId, newCaseState)
            return res
        })
    }, [caseId, setCaseStateMap])

    const fetchData = useCallback(async (url: string, method: string, body?: any) => {
        try {
            const resp = await fetch(url, {
                method: method,
                headers: {
                    ...await getHeaders(),
                    "Content-Type": "application/json",
                    credentials: "same-origin"
                },
                body: body == undefined ? undefined : JSON.stringify(body)
            })
            if (!resp.ok) {
                reporter(`Error fetching data: ${await resp.text()}`)
                if (resp.status === 429) {
                    setLimits((prevState) => {
                        return {
                            limit: Number(resp.headers.get("x-ratelimit-limit-requests")!) ?? prevState.limit,
                            period: Number(resp.headers.get("x-ratelimit-period-requests")!) ?? prevState.period,
                            remaining: 0,
                            cooldown: Number(resp.headers.get("x-ratelimit-cooldown-requests")!) ?? prevState.cooldown
                        }
                    })
                }
                return resp
            }
            setLimits((prevState) => {
                return {
                    limit: Number(resp.headers.get("x-ratelimit-limit-requests")!) ?? prevState.limit,
                    period: Number(resp.headers.get("x-ratelimit-period-requests")!) ?? prevState.period,
                    remaining: Number(resp.headers.get("x-ratelimit-remaining-requests")!) ?? prevState.remaining,
                    cooldown: Number(resp.headers.get("x-ratelimit-cooldown-requests")!) ?? prevState.cooldown,
                    lastUpdated: Date.now()
                }
            })
            return resp
        } catch (e) {
            reporter(`${e}`)
            throw e
        }
    }, [reporter, getHeaders, setLimits])

    const fetchCase = useCallback(async () => {
        try {
            updateSupportCase((prevState) => {
                const supportCase = prevState?.supportCase || emptyCase();
                return {
                    supportCase: supportCase,
                    isLoading: prevState.isLoading,
                    isSyncing: true,
                    progress: prevState.progress,
                }
            })
            const caseResponse = await fetchData("/api/cases/" + caseId, "GET", undefined)
            if (!caseResponse.ok) {
                updateSupportCase((prevState) => {
                    return {
                        supportCase: emptyCase(),
                        isLoading: false,
                        isSyncing: false,
                        progress: new Map(),
                    }
                })
                return
            }
            const caseInfo = await caseResponse.json()

            const messages = caseInfo.messages as BackendMessageResponse[];
            const filteredMessages = messages
                .map(m => {
                    return {
                        "text": m.text,
                        "messageType": m.party == "user" ? CaseMessageType.USER_MESSAGE : CaseMessageType.MESSAGE_RESPONSE,
                        "messageRole": toRole(m.role),
                        "requestedBy": m?.user_id,
                        "timeToRespond": m?.time_to_respond === undefined ? undefined : +m?.time_to_respond,
                        "creationTime": m?.creation_time,
                        "attachment_id": m?.attachment_id
                    } as CaseMessage
                })
                .filter(m => m.messageRole !== CaseMessageRole.SUMMARY && m.messageRole !== CaseMessageRole.ESCALATION)
            updateSupportCase((prevState) => {
                return {
                    supportCase: {
                        ...caseInfo,
                        "messages": filteredMessages
                    },
                    isLoading: prevState?.isLoading || false,
                    isSyncing: false,
                    progress: new Map(),
                }
            })
        } catch (e) {
            updateSupportCase((prevState) => {
                return {
                    supportCase: prevState?.supportCase || emptyCase(),
                    isLoading: prevState.isLoading,
                    isSyncing: false,
                    progress: new Map(),
                }
            })
        }
    }, [updateSupportCase, fetchData, caseId])

    const deleteSupportCase = useCallback(async () => {
        setCaseStateMap(prevState => {
            if (caseId === null || caseId === undefined) {
                return prevState
            }
            const res = new Map(prevState);
            res.delete(caseId)
            return res
        })
    }, [caseId, setCaseStateMap])


    useEffect(() => {
        if (!isLoggedIn() && caseStateMap.size > 0) {
            setCaseStateMap(() => new Map())
            setLastCaseIdLoaded(undefined)
        }
    }, [isLoggedIn, caseStateMap, setCaseStateMap]);

    useEffect(() => {
        if (!isLoggedIn()) {
            return
        }
        if (caseId === null || caseId === undefined) {
            return
        }
        if (caseId === lastCaseIdLoaded) {
            return
        }
        setLastCaseIdLoaded(caseId)
        if (caseStateMap.has(caseId) && caseStateMap.get(caseId)?.isLoading === true) {
            return
        }
        fetchCase()
    }, [caseId, lastCaseIdLoaded, fetchCase, caseStateMap, isLoggedIn])


    return useMemo(() => {
        const caseState = caseId != undefined ? caseStateMap.get(caseId) : undefined
        return {
            supportCaseState: caseState,
            updateSupportCase: updateSupportCase,
            deleteSupportCase: deleteSupportCase,
            caseId: caseId,
            caseStateMapSize: caseStateMap.size,
            limits: limits,
            fetchData: fetchData,
            webSocket: webSocket,
            webSocketReadyState: webSocketReadyState
        }
    }, [updateSupportCase, deleteSupportCase, caseId, caseStateMap, limits, fetchData, webSocket, webSocketReadyState])
}


class ListBuffer implements WebsocketBuffer {
    private buffer: string[] = []

    add(data: string): void {
        this.buffer.push(data)
    }

    read(): string | ArrayBufferLike | Blob | ArrayBufferView | undefined {
        return this.buffer.shift()
    }
}